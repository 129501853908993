import { useRef, useEffect } from "react"

function throttle(fn, milliseconds) {
  let called = false
  return () => {
    if (!called) {
      called = true
      setTimeout(() => {
        called = false
      }, milliseconds)
      fn()
    }
  }
}

export const useAnimatedBorder = (multiplier = 1) => {
  const boxRef = useRef(null)
  useEffect(() => {
    const minScale = -0.5
    const maxScale = 0.5

    const element = boxRef.current
    if (element === null) return

    function updateBorders() {
      if (element !== null) {
        const boxBoundingRect = element.getBoundingClientRect()
        const boxPosition = boxBoundingRect.y
        const newScale =
          minScale +
          (1 -
            (boxPosition + boxBoundingRect.height) /
              (window.innerHeight + boxBoundingRect.height))
        if (newScale > minScale && newScale < maxScale) {
          element.style.setProperty("--scale", newScale)
        }
      }
    }

    const throttledUpdate = throttle(updateBorders, 100)

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          window.addEventListener("scroll", throttledUpdate, { passive: true })
        } else {
          window.removeEventListener("scroll", throttledUpdate)
        }
      })
    })

    observer.observe(element)

    return () => {
      observer.unobserve(element)
    }
  }, [multiplier])
  return [boxRef]
}

export const useAnimatedBackground = () => {
  const backgroundRef = useRef(null)
  useEffect(() => {
    let currentAnimationFrame

    function updateBackground() {
      if (backgroundRef.current !== null) {
        backgroundRef.current.style.transform = `translateX(${
          window.scrollY / 500
        }%) scale(1.2)`
      }
      currentAnimationFrame = requestAnimationFrame(updateBackground)
    }

    currentAnimationFrame = requestAnimationFrame(updateBackground)

    return () => {
      cancelAnimationFrame(currentAnimationFrame)
    }
  }, [])
  return [backgroundRef]
}

export const joinMenuToLocation = (location, menus) => {
  return Object.assign(
    location,
    menus.find((menu) => menu.wordpress_id === location.wordpress_id)
  )
}
