import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const Container = styled.div`
  margin: 0 auto;
  max-width: 85vw;
  position: relative;
  @media (min-width: 1300px) {
    max-width: 1250px;
    ${(props) =>
      props.size === "medium" &&
      css`
        max-width: 1050px;
      `}
  }
  @media (min-width: 1195px) {
    ${(props) =>
      props.size === "small" &&
      css`
        max-width: 850px;
      `}
  }
`

export const BaseButton = styled(Link)`
  display: inline-block;
  min-width: 181px;
  position: relative;
  text-align: center;
  line-height: 1.25;
  width: fit-content;
  font-family: "Montserrat";
  font-size: ${(props) =>
    ["small", "medium"].includes(props.size) ? "1rem" : "1.125rem"};
  padding: ${(props) =>
    props.size === "small"
      ? "0.625rem 1rem"
      : props.size === "medium"
      ? "0.75rem 2.5rem"
      : "0.9375rem 3rem"};
  /* small medium big */
  @media (max-width: 1195px) {
    font-size: 1rem;
    padding: 0.625rem 2rem;
  }
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }
  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`

export const SecondaryButton = styled(Link)`
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.25;
  width: fit-content;
  font-family: "Montserrat";
  font-size: ${(props) =>
    ["small", "medium"].includes(props.size) ? "1rem" : "1.125rem"};
  padding: ${(props) =>
    props.size === "small"
      ? "0.625rem 1rem"
      : props.size === "medium"
      ? "0.75rem 2.5rem"
      : "0.9375rem 3rem"};
  /* small medium big */
  @media (max-width: 1195px) {
    font-size: 1rem;
    padding: 0.625rem 2rem;
  }
  border: none;
  color: ${(props) => props.theme.secondary};
  font-weight: bold;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }
  :hover {
    color: #fff;
    background: ${(props) => props.theme.secondary};
  }
`

export const BorderedHeader = styled.h2`
  margin: 0 -2rem;
  font-size: inherit;
  transform: translateX(
    ${(props) => (props.side === "right" ? "-6rem" : "6rem")}
  );
  white-space: ${(props) => (props.whiteSpace === "nowrap" ? "nowrap" : "")};
  text-align: ${(props) => (props.side === "right" ? "right" : "left")};
  @media (min-width: 1196px) {
    ${(props) =>
      props.size === "big" &&
      css`
        min-width: 620px;
        max-width: 100%;
      `}
  }
  @media (max-width: 1195px) {
    white-space: normal;
    margin: 0 auto;
    transform: none;
    text-align: center;
  }
`

export const SideBox = styled.div`
  position: relative;
  width: 66%;
  padding: 50px 32px;
  margin: 0 0 66px 40px;
  line-height: 1.625;
  border-right: 1px solid ${(props) => props.theme.secondary};
  @media (max-width: 900px) {
    margin: 0 0 66px 0;
    width: 100%;
  }
  h1::first-letter {
    text-transform: capitalize;
  }
  ::before,
  ::after {
    content: "";
    position: absolute;
    right: 0;
    border-left: 1px solid ${(props) => props.theme.secondary};
    width: 66%;
    height: 30px;
    transition: transform 1s ease-out;
  }
  ::before {
    top: 0;
    border-top: 1px solid ${(props) => props.theme.secondary};
    transform: scaleY(${(props) => 1 + props.scale});
    transform-origin: top;
  }
  ::after {
    bottom: 0;
    border-bottom: 1px solid ${(props) => props.theme.secondary};
    transform: scaleY(${(props) => 1 - props.scale});
    transform-origin: bottom;
  }
`
