import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Container } from "./styledComponents"
import { joinMenuToLocation } from "../helpers"
import decodeHtmlCharCodes from "../utils/decodeHtmlCharCodes"
import HeaderSubmenu from "./headerSubmenu"
import BaseImg from "./baseImg"

const StyledHeader = styled.header`
  color: ${(props) => props.theme.primary};
  margin-bottom: 1.45rem;
  padding: 2rem 2rem 1.5rem;
  background-color: ${(props) => (props.active ? "#fff" : "transparent")};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;

  > div {
    position: static;
  }
`

const MenuItem = styled.li`
  list-style: none;
  margin-bottom: 0;
  font-size: 1.125rem;

  &:hover .header-submenu {
    transform: scaleY(100%);
  }

  @media (max-width: 1280px) {
    &:hover .header-submenu > ul {
      min-height: 200px;
      max-height: 200px;
    }
  }

  &:hover > span:before {
    border-bottom: 2px ${(props) => props.theme.secondary} solid;
    border-left: 2px ${(props) => props.theme.secondary} solid;
  }

  a[aria-current="page"] {
    text-decoration: revert;
    text-decoration-color: ${(props) => props.theme.secondary};
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;
  }

  @media (min-width: 1280px) {
    & + & {
      margin-left: 1.75rem;
    }
  }
  @media (max-width: 1280px) {
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
    padding: 0.5rem 0;
  }

  & > a {
    white-space: nowrap;
    padding: 0.75rem 0;
  }
  & > span {
    white-space: nowrap;
    padding: 0.75rem 5px;
  }

  :last-of-type {
    background: ${(props) => props.theme.secondary};
    box-shadow: 0 3px 6px #00000029;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    border: 1px solid ${(props) => props.theme.secondary};
    transition: color 0.1s ease-in-out, background 0.2s ease-in-out;

    :focus-within {
      border-style: dashed;
      background: #fff;
      color: ${(props) => props.theme.secondary};
      outline: none;
    }

    :hover {
      color: ${(props) => props.theme.secondary};
      background: #fff;
    }

    @media (max-width: 1280px) {
      margin-top: 0.5rem;
    }

    a {
      display: block;
      padding: 0.66rem;
      color: inherit;
      @media (max-width: 400px) {
        padding: 0.66rem 1.25rem;
        font-size: 1.25rem;
      }

      :focus {
        outline: none;
      }
    }
  }
`

const HeaderMenu = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  max-width: 85vw;
  @media (max-width: 1280px) {
    background: #fff;
    width: 100%;
    height: 100%;
    transform: ${(props) => (props.isOpen ? "" : "translateX(202vw)")};
    transition: transform 0.3s ease-in-out;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50vh;
  }
`

const HeaderMenuWrapper = styled.div`
  @media (max-width: 1280px) {
    transform: ${(props) => (props.isOpen ? "" : "translateX(-101vw)")};
    transition: transform 0.3s ease-in-out;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    top: 0;
    left: 0;
    width: 100vw;
    height: 110vh;
    z-index: -1;
  }
`

const Hamburger = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  @media (min-width: 1280px) {
    display: none;
  }
  :focus {
    outline: none;
  }
`

const HamburgerBar = styled.div`
  height: 7px;
  width: ${(props) => (props.second ? "39px" : "45px")};
  margin: ${(props) => (props.third ? "0 0 0 auto" : "0 0 12px auto")};
  background-color: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  visibility: ${(props) => (props.isOpen && props.second ? "hidden" : "")};
  transform: ${(props) => {
    if (props.isOpen) {
      if (props.first) {
        return "translateY(19px) rotate(45deg)"
      } else if (props.third) {
        return "translateY(-19px) rotate(-45deg)"
      }
    }
  }};
  transition: transform 0.3s ease-in-out;
`

const LanguageSwitcher = styled.a`
  svg {
    height: 1.5rem;
    vertical-align: middle;
  }
`

const PageHeader = () => {
  const {
    allWordpressWpApiMenusMenusItems,
    allWordpressWpApiMenusMenuLocations,
    file,
  } = useStaticQuery(
    graphql`
      query {
        allWordpressWpApiMenusMenuLocations(
          filter: { label: { eq: "Header" } }
        ) {
          nodes {
            label
            wordpress_id
          }
        }
        allWordpressWpApiMenusMenusItems {
          nodes {
            wordpress_id
            items {
              order
              title
              url
              wordpress_children {
                title
                order
                url
                description
                thumbnail {
                  url {
                    link
                  }
                  alt
                }
              }
            }
          }
        }
        file(name: { eq: "logo" }) {
          publicURL
        }
      }
    `
  )
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [activeMenu, setActiveMenu] = useState(false)

  const headerMenu = joinMenuToLocation(
    allWordpressWpApiMenusMenuLocations.nodes[0],
    allWordpressWpApiMenusMenusItems.nodes
  )

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY < 50) {
        setActiveMenu(false)
      } else {
        setActiveMenu(true)
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <StyledHeader active={activeMenu}>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ margin: 0 }}>
            <Link to="/">
              <BaseImg
                localFile={file}
                style={{ width: "154px", margin: "0" }}
                alt="Blueowl logo"
              />
            </Link>
          </h1>
          <Hamburger
            aria-expanded={isMobileMenuOpen}
            aria-controls="menu"
            aria-label="Navigation"
            type="button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <HamburgerBar isOpen={isMobileMenuOpen} first />
            <HamburgerBar isOpen={isMobileMenuOpen} second />
            <HamburgerBar isOpen={isMobileMenuOpen} third />
          </Hamburger>
          <HeaderMenuWrapper isOpen={isMobileMenuOpen}>
            <HeaderMenu isOpen={isMobileMenuOpen}>
              {headerMenu.items.map((menuItem, index) => (
                <MenuItem key={menuItem.title}>
                  {menuItem.title.includes("<svg") ||
                  menuItem.title.includes("EN") ||
                  menuItem.title.includes("PL") ? (
                    <LanguageSwitcher
                      href={menuItem.url}
                      dangerouslySetInnerHTML={{ __html: menuItem.title }}
                    ></LanguageSwitcher>
                  ) : headerMenu.items.length - 2 === index ? (
                    <Link to={menuItem.url.replace(/http.*\/\/.*?\//g, "/")}>
                      {decodeHtmlCharCodes(menuItem.title)}
                    </Link>
                  ) : headerMenu.items.length - 1 === index ? (
                    <Link to={menuItem.url.replace(/http.*\/\/.*?\//g, "/")}>
                      {decodeHtmlCharCodes(menuItem.title)}
                    </Link>
                  ) : (
                    <Link to={menuItem.url.replace(/http.*\/\/.*?\//g, "/")}>
                      {decodeHtmlCharCodes(menuItem.title)}
                    </Link>
                  )}
                  {menuItem.wordpress_children !== null ? (
                    <HeaderSubmenu menuItems={menuItem.wordpress_children} />
                  ) : (
                    ""
                  )}
                </MenuItem>
              ))}
            </HeaderMenu>
          </HeaderMenuWrapper>
        </div>
      </Container>
    </StyledHeader>
  )
}

export default PageHeader
